/**
 * Homepage
 */

.homepage {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.3;
  position: relative;
}

.homepage__content {
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  max-width: 1024px;
  padding: 20px;
  row-gap: 20px;
  width: 100%;
}

.homepage__content h1 {
  font-weight: bold;

  @media screen and (max-width: 1023px) {
    font-size: 2.5em;
  }
  @media screen and (min-width: 1023px) {
    font-size: 3em;
  }
}

.homepage__projects {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  row-gap: 10px;
}

.homepage__project {
  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    span {
      border-left: 1px solid var(--color-border);
      padding-left: 20px;
    }
  }
  @media screen and (min-width: 1023px) {
    column-gap: 20px;
    display: grid;
    grid-template-columns: 1fr 4fr;

    a {
      text-align: right;
    }
  }
}
